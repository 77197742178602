<template>
  <div>
    <DContenidoPagina titulo="Administración de Reservados">
      <div>
        <v-list three-line>
          <template v-for="(item, index) in reservados.slice().reverse()">
            <v-card :key="index" class="mx-auto mt-3" elevation="5">
              <v-card-text style="padding: 5px 15px">
                <div class="d-flex flex-row-reverse mb-1">
                  <v-chip :color="item.estado | formatColor" small dark>
                    {{ item.estado }}
                  </v-chip>
                  <v-chip outlined small class="mr-2">
                    Reservado el: {{ item.fecha }}
                  </v-chip>
                  <v-chip outlined small class="mr-2">
                    Tipo: {{ item.tipo }}
                  </v-chip>
                </div>

                <v-divider></v-divider>
                <v-row no-gutters>
                  <v-col cols="6">
                    <div class="text-h6 text--primary">Datos del Usuario</div>
                    <p class="text--primary">Nombre: {{ item.nombre }}</p>
                    <p class="text--primary">CI: {{ item.ci }}</p>
                    <p class="text--primary">Celular: {{ item.celular }}</p>
                  </v-col>
                  <v-col cols="6">
                    <div class="text-h6 text--primary">Datos del vehículo</div>
                    <p class="text--primary">
                      Vehículo: {{ item.marcamodelo }}
                    </p>
                    <p class="text--primary">Placa: {{ item.placa }}</p>
                    <p class="text--primary">Detalle: {{ item.detalle }}</p>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  dark
                  small
                  @click="administrar(item)"
                  color="deep-purple accent-4"
                >
                  Historial
                </v-btn>
                <v-btn
                  outlined
                  dark
                  small
                  @click="cambiarEstado(item)"
                  color="green"
                >
                  Cambiar de estado
                </v-btn>
                <v-btn
                  outlined
                  dark
                  small
                  @click="contactar(item)"
                  color="green"
                >
                  Contactar
                </v-btn>
                <v-btn
                  outlined
                  dark
                  v-if="item.estado == 'Finalizado'"
                  small
                  @click="eliminarR(item)"
                  color="red"
                >
                  Eliminar
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-list>
      </div>
      <DModal
        titulo="Historial"
        v-model="dialogo"
        ocutarFooter
        persistent
        @cerrar="dialogo = false"
      >
        <Seguimientos v-if="dialogo" :item="itemSelect" />
      </DModal>
      <DModal
        titulo="Enviar Mensaje"
        v-model="dialogoContacto"
        ocutarFooter
        persistent
        @cerrar="dialogoContacto = false"
      >
        <Contacto v-if="dialogoContacto" :item="itemSelect" />
      </DModal>
      <!-- dialog modificar estado -->
      <div>
        <v-dialog v-model="dialogEstado" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5"> Cambiar Estado </v-card-title>
            <v-card-text>
              <div>
                <v-list three-line>
                  <v-list-item-group
                    v-model="selected"
                    active-class="blue--text"
                  >
                    <template v-for="(item, index) in estados">
                      <v-list-item :key="item.title" :value="item">
                        <template v-slot:default="{ active }">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-chip
                                :color="item.valor | formatColor"
                                small
                                dark
                              >
                                {{ item.valor }}
                              </v-chip>
                            </v-list-item-title>

                            <v-list-item-subtitle
                              v-text="item.detalle"
                            ></v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-icon v-if="!active" color="grey lighten-1">
                              mdi-hexagon-outline
                            </v-icon>

                            <v-icon v-else color="blue darken-3">
                              mdi-check
                            </v-icon>
                          </v-list-item-action>
                        </template>
                      </v-list-item>

                      <v-divider
                        v-if="index < estados.length - 1"
                        :key="index"
                      ></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="
                  dialogEstado = false;
                  selected = null;
                "
              >
                Cancelar
              </v-btn>
              <v-btn
                :disabled="selected == null"
                color="green darken-1"
                text
                @click="cambiarRegistro()"
              >
                Cambiar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <!-- dialog eliminar registro -->
      <div>
        <v-dialog v-model="dialogEliminar" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5"> Eliminar Registro </v-card-title>
            <v-card-text
              >El registro se eliminara de forma permanente de la
              lista</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="dialogEliminar = false"
              >
                Cancelar
              </v-btn>
              <v-btn color="red darken-1" text @click="eliminarRegistro()">
                Eliminar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </DContenidoPagina>
  </div>
</template>

<script>
import { DContenidoPagina, DModal } from "../../components";
import Seguimientos from "./reservas/seguimientos.vue";
import Contacto from "./reservas/contacto.vue";
import app from "../../firebase";
import {
  getDatabase,
  ref,
  set,
  remove,
  onChildChanged,
  onChildAdded,
  onChildRemoved,
} from "firebase/database";
export default {
  components: {
    DContenidoPagina,
    DModal,
    Seguimientos,
    Contacto,
  },
  data() {
    return {
      dialogo: false,
      dialogEstado: false,
      dialogoContacto: false,
      dialogEliminar: false,
      selected: null,
      esNuevo: true,
      reservados: [],
      estados: [
        { valor: "Reservado", detalle: "Estado cuando se encuentra reservado" },
        {
          valor: "Proceso",
          detalle: "Estado cuando se encuentra ya en el taller",
        },
        {
          valor: "Finalizado",
          detalle: "Estado cuando se finaliza el trabajo",
        },
        {
          valor: "Entregado",
          detalle: "Estado cuando es devuelto al propietario",
        },
      ],
      itemSelect: {},
    };
  },
  mounted() {
    //const recentPostsRef = query(ref(serviciosDB, 'servicios'), limitToLast(100));
    const db = getDatabase(app);
    const commentsRef = ref(db, "reservados");
    onChildAdded(commentsRef, (data) => {
      console.log(data.val());
      this.reservados.push({
        key: data.key,
        nombre: data.val().nombre,
        ci: data.val().ci,
        celular: data.val().celular,
        marcamodelo: data.val().marcamodelo,
        placa: data.val().placa,
        fecha: data.val().fecha,
        estado: data.val().estado,
        tipo: data.val().tipo,
        detalle: data.val().detalle,
        token: data.val().token,
      });
    });
    onChildChanged(commentsRef, (data) => {
      for (let i = 0; i < this.reservados.length; i++) {
        if (this.reservados[i].key == data.key) {
          this.reservados[i].nombre = data.val().nombre;
          this.reservados[i].ci = data.val().ci;
          this.reservados[i].celular = data.val().celular;
          this.reservados[i].marcamodelo = data.val().marcamodelo;
          this.reservados[i].placa = data.val().placa;
          this.reservados[i].fecha = data.val().fecha;
          this.reservados[i].estado = data.val().estado;
          this.reservados[i].tipo = data.val().tipo;
          this.reservados[i].detalle = data.val().detalle;
          this.reservados[i].token = data.val().token;
        }
      }
    });

    onChildRemoved(commentsRef, (data) => {
      for (let i = 0; i < this.reservados.length; i++) {
        if (this.reservados[i].key == data.key) {
          this.reservados.splice(i, 1);
        }
      }
    });
  },
  methods: {
    contactar(item) {
      this.itemSelect = item;
      this.dialogoContacto = true;
    },
    administrar(item) {
      this.itemSelect = item;
      this.dialogo = true;
    },
    cambiarEstado(item) {
      this.itemSelect = item;
      this.dialogEstado = true;
    },
    cambiarRegistro() {
      const db = getDatabase(app);
      if (this.selected.valor == "Entregado") {
        remove(ref(db, "reservados/" + this.itemSelect.key));
        this.$store.commit("alert", {
          color: "warning",
          text: "Se archivo el registro",
        });
        this.selected = null;
        this.dialogEstado = false;
      } else {
        set(
          ref(db, "reservados/" + this.itemSelect.key + "/" + "estado"),
          this.selected.valor + ""
        );
        this.selected = null;
        this.dialogEstado = false;
        this.$store.commit("alert", {
          color: "success",
          text: "Se modifico de forma correcta",
        });
      }
    },
    eliminarR(item) {
      this.itemSelect = item;
      this.dialogEliminar = true;
    },
    eliminarRegistro() {
      const db = getDatabase(app);
      remove(ref(db, "reservados/" + this.itemSelect.key));
      this.dialogEliminar = false;
      this.$store.commit("alert", {
        color: "success",
        text: "Se elimino de forma correcta",
      });
    },
  },
};
</script>

<style></style>
