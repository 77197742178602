<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            label="Nueva Notificacion"
            v-model="nuevaNotificacion"
            outlined
            dense
            :rules="[
              (v) => !!v || 'texto es requerido',
              (v) =>
                (v && v.length >= 5 && v.length <= 100) ||
                'Text debe tener entre 5 y 100 caracteres',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <div
            class="grid-btn d-flex mb-4 justify-sm-center flex-sm-row flex-column"
          >
            <v-btn
              outlined
              small
              :disabled="nuevaNotificacion.length <= 0 || loading"
              color="indigo"
              class="mr-2"
              :loading="loading"
              @click="enviarNotificacion()"
            >
              Enviar Notificacion
            </v-btn>
            <v-btn
              outlined
              small
              :disabled="nuevaNotificacion.length <= 0"
              color="green"
              @click="enviarWhats()"
            >
              Enviar WhatsApp {{ item.celular }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12">
          <div>
            * Al enviar por NOTIFICACION se realiza un mensaje directo al
            celular quien realizo la reserva
          </div>
          <div>
            * Al enviar por WHATSAPP se realiza un mensaje al celular registro
            en reserva
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["item"],
  data() {
    return {
      nuevaNotificacion: "",
      loading: false,
    };
  },
  methods: {
    enviarWhats() {
      //   location.href =
      //     "https://api.whatsapp.com/send?phone=65262889&text=Hola, Nececito mas informacion!";
      var cadenaCompleta =
        "https://api.whatsapp.com/send?phone=" +
        this.item.celular +
        "&text=" +
        this.nuevaNotificacion;
      window.open(cadenaCompleta, "_blank");
    },
    async enviarNotificacion() {
      this.loading = true;
      const headers = {
        Authorization: "key=" + process.env.VUE_APP_KEY_FCM,
      };
      const content = {
        data: {
          key: this.item.key,
          titulo: "Mensaje: De " + this.item.marcamodelo,
          mensaje: this.nuevaNotificacion,
          tipo: 2,
        },
        to: this.item.token,
      };
      const response = await axios.post(
        "https://fcm.googleapis.com/fcm/send",
        content,
        { headers }
      );
      this.loading = false;
      //console.log(response);
      if (response.status == 200) {
        if (response.data.success == 1) {
          this.$store.commit("alert", {
            color: "success",
            text: "Se envio la Notificacion",
          });
        } else {
          this.$store.commit("alert", {
            color: "warning",
            text: "Ocurrio algun error con el dispositivo receptor",
          });
        }
      } else {
        this.$store.commit("alert", {
          color: "error",
          text: "Ocurrio algun error al enviar",
        });
      }
      this.nuevaNotificacion = "";
    },
  },
};
</script>

<style></style>
