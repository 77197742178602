<template>
  <div>
    <DContenidoPagina
      :titulo="
        'Lista seguimiento del vehículo - ' +
        item.marcamodelo +
        ' - de  ' +
        item.nombre
      "
    >
      <v-row no-gutters>
        <v-col>
          <!-- <v-timeline align-top dense>
            <template v-for="(item, index) in seguimiento">
              <v-timeline-item color="pink" small :key="index">
                <v-row class="pt-1">
                  <v-col cols="5">
                    <strong>{{ item.fecha }}</strong>
                  </v-col>
                  <v-col>
                    <strong>{{ item.descripcion }}</strong>
                    <div class="text-caption">{{ item.estado }}</div>
                    <div v-if="!item.estado">
                      <v-text-field
                        label="Titulo"
                        v-model="nuevoSeguimiento.titulo"
                        outlined
                        dense
                        :rules="[
                          (v) => !!v || 'texto es requerido',
                          (v) =>
                            (v && v.length >= 5 && v.length <= 100) ||
                            'Text debe tener entre 5 y 100 caracteres',
                        ]"
                      ></v-text-field>
                      <v-text-field
                        label="Descripcion"
                        v-model="nuevoSeguimiento.descripcion"
                        outlined
                        dense
                        :rules="[
                          (v) => !!v || 'texto es requerido',
                          (v) =>
                            (v && v.length >= 5 && v.length <= 100) ||
                            'Texto debe tener entre 5 y 100 caracteres',
                        ]"
                      ></v-text-field>
                      <v-btn
                        class="ma-2"
                        outlined
                        small
                        color="indigo"
                        @click="registrarSeguimiento(item)"
                      >
                        Registrar Avance
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </template>
          </v-timeline> -->

          <v-timeline align-top dense>
            <v-timeline-item
              v-for="(timeline, i) in seguimiento"
              :key="i"
              :color="timeline.estado ? 'green' : 'grey'"
              :icon="timeline.estado ? 'mdi-check' : 'mdi-text-short'"
              fill-dot
              small
            >
              <v-card class="pa-3">
                <div class="d-flex flex-row-reverse mb-1">
                  Paso: {{ i + 1 }}
                </div>
                <v-divider class="mb-3" />
                <div v-if="timeline.estado">
                  <v-chip color="blue" dark class="overline mb-3" small>
                    fecha:{{ timeline.fecha }}
                  </v-chip>

                  <p
                    class="subtitle-1 text--primary"
                    v-text="timeline.titulo"
                  ></p>

                  <div class="body-2">
                    Descripción: {{ timeline.descripcion }}
                  </div>
                </div>
                <template v-if="!timeline.estado">
                  <v-divider class="mb-3" />
                  <div>
                    <v-text-field
                      label="Título"
                      v-model="nuevosSeguimientos[i].titulo"
                      outlined
                      dense
                      :rules="[
                        (v) => !!v || 'texto es requerido',
                        (v) =>
                          (v && v.length >= 5 && v.length <= 100) ||
                          'Text debe tener entre 5 y 100 caracteres',
                      ]"
                    ></v-text-field>
                    <v-text-field
                      label="Descripción"
                      v-model="nuevosSeguimientos[i].descripcion"
                      outlined
                      dense
                      :rules="[
                        (v) => !!v || 'texto es requerido',
                        (v) =>
                          (v && v.length >= 5 && v.length <= 100) ||
                          'Texto debe tener entre 5 y 100 caracteres',
                      ]"
                    ></v-text-field>
                    <v-btn
                      class="ma-2"
                      outlined
                      small
                      :disabled="
                        nuevosSeguimientos[i].titulo.length <= 0 ||
                        nuevosSeguimientos[i].descripcion.length <= 0
                      "
                      color="indigo"
                      @click="registrarSeguimiento(timeline, i)"
                    >
                      Registrar Avance
                    </v-btn>
                  </div>
                </template>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
    </DContenidoPagina>
  </div>
</template>

<script>
import { DContenidoPagina } from "../../../components";
import app from "../../../firebase";
import {
  getDatabase,
  ref,
  set,
  onChildChanged,
  onChildAdded,
  onChildRemoved,
} from "firebase/database";
import axios from "axios";
export default {
  components: {
    DContenidoPagina,
  },
  props: ["item"],
  data() {
    return {
      dialogoC: false,
      esNuevo: true,
      seguimiento: [],
      cargando: false,
      nuevoSeguimiento: {
        titulo: "",
        descripcion: "",
        fecha: "",
        estado: "",
      },
      nuevaNotificacion: "",
      nuevosSeguimientos: [],
    };
  },
  mounted() {
    const db = getDatabase(app);
    const commentsRef = ref(db, "reservados/" + this.item.key + "/pasos");

    onChildAdded(commentsRef, (data) => {
      this.seguimiento.push({
        key: data.key,
        descripcion: data.val().descripcion,
        estado: data.val().estado,
        fecha: data.val().fecha,
        titulo: data.val().titulo,
      });
    });
    onChildChanged(commentsRef, (data) => {
      for (let i = 0; i < this.seguimiento.length; i++) {
        if (this.seguimiento[i].key == data.key) {
          this.seguimiento[i].descripcion = data.val().descripcion;
          this.seguimiento[i].estado = data.val().estado;
          this.seguimiento[i].fecha = data.val().fecha;
          this.seguimiento[i].titulo = data.val().titulo;
        }
      }
    });
    onChildRemoved(commentsRef, (data) => {
      for (let i = 0; i < this.seguimiento.length; i++) {
        if (this.seguimiento[i].key == data.key) {
          this.seguimiento.splice(i, 1);
        }
      }
    });

    for (let index = 0; index < this.seguimiento.length; index++) {
      this.nuevosSeguimientos.push({
        titulo: "",
        descripcion: "",
      });
    }
  },
  methods: {
    registrarSeguimiento(itemSelect, index) {
      const db = getDatabase(app);
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      //const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      set(ref(db, "reservados/" + this.item.key + "/pasos/" + itemSelect.key), {
        titulo: this.nuevosSeguimientos[index].titulo,
        descripcion: this.nuevosSeguimientos[index].descripcion,
        fecha: date,
        estado: true,
      });
      this.enviarNotificacion(
        this.nuevosSeguimientos[index].titulo,
        this.nuevosSeguimientos[index].descripcion
      );
    },
    async enviarNotificacion(titulo, descripcion) {
      this.loading = true;
      const headers = {
        Authorization: "key=" + process.env.VUE_APP_KEY_FCM,
      };
      const content = {
        data: {
          key: this.item.key,
          titulo: "Avance Registrado: De " + this.item.marcamodelo,
          mensaje: "Titulo: " + titulo + "\n" + descripcion,
          tipo: 1,
        },
        to: this.item.token,
      };
      const response = await axios.post(
        "https://fcm.googleapis.com/fcm/send",
        content,
        { headers }
      );
      this.loading = false;
      //console.log(response);
      if (response.status == 200) {
        if (response.data.success == 1) {
          this.$store.commit("alert", {
            color: "success",
            text: "Se envio la Notificacion",
          });
        } else {
          this.$store.commit("alert", {
            color: "warning",
            text: "Ocurrio algun error con el dispositivo receptor",
          });
        }
      } else {
        this.$store.commit("alert", {
          color: "error",
          text: "Ocurrio algun error al enviar",
        });
      }
    },
  },
};
</script>

<style></style>
