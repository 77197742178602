var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DContenidoPagina',{attrs:{"titulo":'Lista seguimiento del vehículo - ' +
      _vm.item.marcamodelo +
      ' - de  ' +
      _vm.item.nombre}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-timeline',{attrs:{"align-top":"","dense":""}},_vm._l((_vm.seguimiento),function(timeline,i){return _c('v-timeline-item',{key:i,attrs:{"color":timeline.estado ? 'green' : 'grey',"icon":timeline.estado ? 'mdi-check' : 'mdi-text-short',"fill-dot":"","small":""}},[_c('v-card',{staticClass:"pa-3"},[_c('div',{staticClass:"d-flex flex-row-reverse mb-1"},[_vm._v(" Paso: "+_vm._s(i + 1)+" ")]),_c('v-divider',{staticClass:"mb-3"}),(timeline.estado)?_c('div',[_c('v-chip',{staticClass:"overline mb-3",attrs:{"color":"blue","dark":"","small":""}},[_vm._v(" fecha:"+_vm._s(timeline.fecha)+" ")]),_c('p',{staticClass:"subtitle-1 text--primary",domProps:{"textContent":_vm._s(timeline.titulo)}}),_c('div',{staticClass:"body-2"},[_vm._v(" Descripción: "+_vm._s(timeline.descripcion)+" ")])],1):_vm._e(),(!timeline.estado)?[_c('v-divider',{staticClass:"mb-3"}),_c('div',[_c('v-text-field',{attrs:{"label":"Título","outlined":"","dense":"","rules":[
                      function (v) { return !!v || 'texto es requerido'; },
                      function (v) { return (v && v.length >= 5 && v.length <= 100) ||
                        'Text debe tener entre 5 y 100 caracteres'; } ]},model:{value:(_vm.nuevosSeguimientos[i].titulo),callback:function ($$v) {_vm.$set(_vm.nuevosSeguimientos[i], "titulo", $$v)},expression:"nuevosSeguimientos[i].titulo"}}),_c('v-text-field',{attrs:{"label":"Descripción","outlined":"","dense":"","rules":[
                      function (v) { return !!v || 'texto es requerido'; },
                      function (v) { return (v && v.length >= 5 && v.length <= 100) ||
                        'Texto debe tener entre 5 y 100 caracteres'; } ]},model:{value:(_vm.nuevosSeguimientos[i].descripcion),callback:function ($$v) {_vm.$set(_vm.nuevosSeguimientos[i], "descripcion", $$v)},expression:"nuevosSeguimientos[i].descripcion"}}),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","small":"","disabled":_vm.nuevosSeguimientos[i].titulo.length <= 0 ||
                      _vm.nuevosSeguimientos[i].descripcion.length <= 0,"color":"indigo"},on:{"click":function($event){return _vm.registrarSeguimiento(timeline, i)}}},[_vm._v(" Registrar Avance ")])],1)]:_vm._e()],2)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }